:root {
  --color-red: #bf0000;
  --color-red-dark: #a72a2a;
}

.App {
  width: 100%;
  height: 100vh;
  background: #f1dfdf;
  overflow: hidden;
  position: relative;
  .mobile-header {
    // display: none;
    margin-left: 0;
    margin-right: 20px;
    z-index: 101;
    & > svg {
      width: 22px;
      cursor: pointer;
      padding: 10px;       
    }
    .burger-icon {
      fill: black;
    }
    .close-icon {
      fill: white;     
    }
  } 
  .subpage {
    background: #fff;
    height: 100vh;
    box-sizing: border-box;
    padding: 80px 50px 50px;
    overflow: auto;
    animation: enterSubpage 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
    p, blockquote, ul {
      color: #393939;
      max-width: 1000px;      
    }
    h2 {
      font-weight: 500;
      font-size: 20px;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    ul {
      padding: 10px 0 10px 12px;
      list-style-type: square;
      li {
        margin: 10px 0;
        padding-left: 10px;
      }
    }
    a {
      color: var(--color-red);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    blockquote {
      border-left: 2px solid silver;
      margin-left: 0;
      padding-left: 20px;
      font-style: italic;
    }
    .columns {
      display: flex;
      margin: 0 -20px;
      .col {
        flex: 1;
        margin: 0 20px;
        & > *:first-child {
          margin-top: 0;
        }
      }
    }    
  }
  .mappage {
    .Footer {
      position: fixed;
      bottom: 0;
      left: 0;  
      right: 0;   
      padding: 30px 40px;
    }    
  }  
  .country-groups {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #eae3e3;
      padding-bottom: 30px;
    }
  }
  .team-members {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: auto;
    justify-content: center;
    & > div + div {
      margin-left: 50px;
    }
    & > div {
      max-width: 250px;
      text-align: center;
    }
    p {
      margin: 0;
    }
    a {
      font-size: 14px;
    }
    .name {
      font-weight: 600;
    }
    .title {
      color: #8d8d8d;
    }
    .pic {
      width: 150px;
      height: 150px;
      background-size: cover;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 20px;
      background-color: silver;
    }
  }
  .articles {
    margin-bottom: auto;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px auto;
    .article-thumb {
      margin: 20px;
      width: 100%;
      max-width: 300px;
      height: 200px;
      .pic {
        height: 200px;
        background-size: cover;
        background-position: center;
        position: relative;
        .title {
          display: flex;
          align-items: flex-end;
          margin: 0;
          padding: 20px;
          box-sizing: border-box;
          color: white;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
        }
      }
      &:hover {
        .title {
          background: rgba(0, 0, 0, 0.4);
        }
      }
    }
    .article-detail {
      width: 100%;
      .pic {
        width: 100%;
        height: 500px;
        background-size: cover;
        background-position: center;
        position: relative;        
      }
      .content {
        h1 {
          margin-top: 0;
        }        
        max-width: 1000px;
        margin: 0 auto;
        background: white;
        position: relative;
        top: -250px;
        padding: 50px;
        .authors {
          display: flex;
          .author {
            margin-right: 40px;
          }
        }
        .author {
          margin: 20px 0 0;
          display: flex;
          p {
            margin: 0;
            &:first-child {
              font-size: 14px;
            }
            &:last-child {
              font-weight: 500;
            }
          }
          display: flex;
          align-items: center;
        }
        .author-pic {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          margin-right: 10px;
          background-size: cover;
          background-position: center;
        }        
      }
    }
  }
  .subpage-header {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    h1 {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 20px;
      font-size: 24px;
      font-weight: 500;      
    }
  }

  .view-switch {
    position: absolute;
    top: 120px;
    left: 50px;
    & > div {
      display: flex;
      &:first-child {
        p {
          margin: 0;
        }
        margin-bottom: 10px;
        background: white;
        padding: 4px 12px;
        border-radius: 20px;
      }
    }
    p {
      font-size: 14px;
      font-weight: 600;
      margin-top: 0;
    }
    a {
      display: block;
      width: 32px;
      height: 32px;
      background-size: cover;
      border-radius: 25px;
      text-indent: -999pt;
      margin-right: 15px;
      transition: all 0.2s ease-in-out;
      border: 2px solid white;
      &:not(.active) {
        opacity: 0.75;
        // transform: scale(0.9);
      }
      &:hover {
        transform: scale(1.1);
        opacity: 1;
      }
    }
  }

  label {
    color: grey;
    font-size: 13px;
    margin-bottom: 16px;
    display: inline-block;
  }

  .back-button {
    background: #fff;
    border: 1px solid #eeeeee;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 10px;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
      fill: var(--color-red-dark);
    }
  } 
  
  .google-form-iframe {
    height: 1700px;
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
}

@media screen and (max-width: 767px) {
  .App {      
    .mobile-header {
      display: block;
    }
    .mappage {   
      .Footer {
        bottom: 20px;
        left: 30px;  
        right: 30px;   
        padding: 0;           
      }  
    }  
    .view-switch {
      top: 140px;
      left: 30px;      
    }
    .subpage {
      padding: 70px 35px 40px;
      .columns {
        display: block;
        .col {
          display: block;
        }
      }       
      .articles {
        margin: 0 -35px auto;
        .article-thumb {
          max-width: none;
          margin-bottom: 20px;
        }
        .article-detail {
          .pic {
            height: 200px;
          }
          .content {
            top: 0;
            padding: 30px;
            .authors {
              display: block;
              .author {
                margin-right: 0;
              }
            }            
          }
        }
      } 
    }    
    .team-members {
      & > div {
        margin: 10px 0 20px;
        width: 100%;
      }
      & > div + div {
        margin: 10px 0 20px;
      }
    }
    .google-form-iframe {
      height: 2000px;
    }    
  }
}

@keyframes enterSubpage {
  0% {
    transform: scale(1.05);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}