.CountryDetail {
  .group {
    margin-bottom: 30px;
  }  
  .disclaimer {
    background: #fff5e3;
    color: #a16900;
    padding: 10px;
    font-size: 11px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}
