.HeaderPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 200;
  box-shadow: 0px 0px 4px rgba(20, 20, 20, 0.25);
  .logo {
    margin-left: 20px;
    flex-shrink: 0;
  }
  .header-bars-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
    margin-left: auto;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
  }
  .header-search-bar {
    width: 260px;
    box-sizing: border-box;
  }
  .header-country-select {
    margin-left: auto;
    min-width: 250px;
    margin-right: 20px;
  }
  .mobile-navigation-mask {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
    &.visible {
      pointer-events: all;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .mobile-navigation {
    width: 300px;
    background: white;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
    padding: 40px 40px 0;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    transform: translateX(-100%);
    &.visible {
      transform: translateX(0);
    }    
    a {
      display: block;
      font-size: 20px;
      font-weight: 600;
      color: #973b3b;
      margin-bottom: 25px;
      text-decoration: none;
    }
    .SocialIcons {
      display: flex;
    }
  }  
}

@media screen and (max-width: 767px) {
  .HeaderPanel {
    justify-content: space-between;
    .header-bars-container {
      padding: 0 20px;
      justify-content: center;
      flex-direction: row-reverse;
    }
    .mobile-header {
      margin-right: 10px;
    }
    .header-filter-bar {
      // display: none;
    }
    .header-search-bar {
      flex: 1;
      padding: 0;
      width: 100%;
    }
    .anchored-header {
      .back-header {
        left: auto;
      }
    }
    &.is-map-page {
      height: 120px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      .header-search-bar {
        margin-left: 0;
        flex: 1;
      }
      .mobile-header {
        top: 0px;
        right: 0px;
        position: absolute;
      }
    }      
  }
}