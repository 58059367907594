.Footer {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 40px;
  position: relative;
  pointer-events: none;
  .SocialIcons {
    margin-left: 40px;    
  }
  & > div {
    display: flex;
    align-items: flex-end;
    pointer-events: all;
  }
  .copyright {
    font-weight: 600;
    padding: 5px 0;
    display: block;
    a {
      color: var(--color-red-dark);
      text-decoration: none;
      &:hover {
        color: var(--color-red);
      }
    }
  }
  .link {
    text-decoration: none;
    display: inline-block;
    padding: 5px 0;
    color: var(--color-red-dark);
    font-weight: 500;
    margin-left: 30px;
    &:hover {
      color: var(--color-red);
    }
  } 
}  

@media screen and (max-width: 767px) {
  .Footer {
    .SocialIcons {
      display: none;
    }
    .navigation {
      display: none;
    }
  }
}