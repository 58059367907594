.Drawer {
  background: white;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  max-width: 600px;
  width: 40%;
  z-index: 100;
  box-shadow: 0px 0px 4px rgba(20, 20, 20, 0.25);
  &.filters-open {
    top: 60px;
  }
  h1, h2 {
    margin-top: 0;
    font-size: 22px;
    font-weight: 500;
  }  
  .anchored-header {
    padding: 90px 40px 20px;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    width: 100%;
    background-color: white;
  }
  .scrollable-content {
    flex: 1;
    background: white;
    margin-top: 210px;
    padding: 0 40px 30px;
    overflow-y: auto;
    &.no-country-select {
      margin-top: 150px;
    }
  }
  .CountrySelect {
    margin-top: 20px;
  }
  .back-header {
    display: flex;
    align-items: center;
    position: relative;
    left: -60px;
    h1, .back-button-text {
      font-weight: 500;
      font-size: 18px;
      margin: 0 0 0 20px;
    }
    &:hover {
      cursor: pointer;
      fill: var(--color-red-dark);
      color: var(--color-red-dark);
    }    
  }
  .back-button {
    background: #fff;
    border: 1px solid #eeeeee;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 10px;
    position: relative;
    cursor: pointer;
  }
  &.hidden {
    transform: translateX(100%);
    opacity: 0;
  }
}

@media screen and (max-width: 767px) {
  .Drawer {
    width: 100%;
    max-width: none;
    bottom: 0;
    padding-top: 120px;
    &.filters-open {
      top: 180px;
    }    
    .anchored-header {
      top: 50px;
      padding-bottom: 20px;
    }
    .scrollable-content {
      margin-top: 140px;
      &.no-country-select {
        margin-top: 80px;
      }
    }
    .back-title {
      display: none;
    }
    .back-header {
      left: 0;
    }
    &.hidden {
      transform: translateY(100%);
      opacity: 0;
    }    
  }
}