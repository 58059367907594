.Logo {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  img {
    width: 50px;
    display: block;
  }
  span {
    &:last-child {
      color: var(--color-red);
      font-size: 16px;
    }
    font-size: 20px;
    color: black;
    font-weight: 600;
    margin-left: 10px;
  }
  .mobile {
    display: none;
  }  
}

@media screen and (max-width: 1024px) {
  .Logo {
    span {
      font-size: 16px;
      &:last-child {
        font-size: 14px;
      }
    }
  }
}