.MapChart {
  height: 100vh;
  width: 100%;
  animation: enterSubpage 0.2s ease-in-out;
  .map-marker {
    pointer-events: bounding-box;
    cursor: pointer;
    text {
      font-weight: bold;
      fill: #fff;
    }
    .circle-glow {
      fill: #da5d5d;
    }
    .circle-inner {
      fill: #a51010;
    }
    &:hover, &.selected {
      z-index: 100;
      .circle-inner {
        fill: #266D8E;
      }
      .circle-glow {
        fill: #4599c0;       
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .MapChart {
    .map-marker {
      transform: scale(2);
    }
  }
}