.FilterBar {
  position: relative;
  display: flex;
  gap: 10px;
  .filters-container {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background: white;
    border-top: 1px solid #e0e0e0;
    padding: 10px;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    z-index: -1;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    gap: 10px;
    flex-wrap: wrap;
    box-shadow: 0px 2px 2px rgba(20, 20, 20, 0.15);
  }
  .filters-container > * {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }
  .filters-container.open {
    transform: scaleY(1);
  }
  .filters-container.open > * {
    opacity: 1;
    transition: opacity 0.2s ease-in-out 0.2s;
  }
  .filters-button, .clear-filters-button {
    background: white;
    cursor: pointer;
    position: relative;
    border: 1px solid #cbc5c5;
    border-radius: 8px;
    font-size: 14px;        
    display: flex;
    height: 38px;
    padding: 0 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 500;
    color: #5a5a5a;
    gap: 10px;
    box-shadow: 0px 2px 2px rgba(20, 20, 20, 0.15);
    
    svg {
      fill: #838383;
    }
    .filter-indicator {
      display: block;
      position: absolute;
      background: var(--color-red);
      box-shadow: 0px 0px 5px var(--color-red);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      top: 10px;
      left: 10px;
    }
    &:hover:not(:disabled) {
      background: #f9f9f9;
    }
    &:disabled {
      border-color: #e0e0e0;
      cursor: not-allowed;
    }
  }
  .clear-filters-button {
    background: var(--color-red);
    border-color: var(--color-red);
    color: #fff;
    &:hover:not(:disabled) {
      background: var(--color-red-dark);
    }
  }
  .priority-option {
    display: flex;
    gap: 6px;
    align-items: center;
  }
}

@media screen and (max-width: 1100px) {
  .FilterBar {
    .clear-filters-button {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .FilterBar {
    .filters-container {
      top: 120px;
      flex-direction: column;
      flex: 1;
    }
  }
}