.InstitutionDetail {
  h1 {
    margin-bottom: 0;
  }
  .city-name {
    color: grey;
    margin-top: 5px;
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 500;
  }
  .type-group {
    .type-name {
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 10px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      .CountryFlag {
        margin-right: 5px;
      }
    }
    a {
      display: block;
      color: #a51010;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: #da5d5d;
      }
    }
  }
  .type-group + .type-group {
    margin-top: 30px;
  }
}
