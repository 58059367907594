.CountrySelect {
  padding-bottom: 0;
  button {
    border-radius: 8px;
    background: white;
    & > span:first-child {
      padding-left: 0;
    }
    & > span:last-child {
      font-size: 14px;
    }
  }
  input {
    outline: none;
    border: none;
    padding-left: 0;
    padding-top: 10px;
    font-size: 14px;
    border-bottom: 1px solid silver;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #838383;
      opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #838383;
    }          
  }
  ul {
    border-radius: 8px;
  }
  ul div:first-child {
    padding-top: 0;
  }
}
