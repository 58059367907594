.LinkDetail {
  padding-bottom: 20px;
  h1 {
    margin-bottom: 0;
    &.cn-institution {
      color: #a51010;
    }
  }
  .priorities {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    margin: 10px 0;
    .priority-icon {
      flex: 1;
      background: #4b4d51;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      svg {
        fill: white;
      }
    }
  }
  .city-name {
    color: grey;
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    .CountryFlag {
      margin-right: 5px;
    }    
  }
  .type-group {
    .type-name {
      font-weight: 500;
      margin-top: 0;
    }
    a {
      display: block;
      color: #a51010;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: #da5d5d;
      }
    }
  }
  .info-wrapper {
    display: flex;
    width: 100%;
    word-break: break-word;
    & > div {
      flex: 1;
    }
    & > div + div {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid #eeeded;
    }
    span {
      font-size: 15px;
    }
    label {
      display: block;
      margin-bottom: 5px;
      margin-top: 20px;
      &.info-text {
        font-weight: 500;
        font-style: italic;
        margin-bottom: -10px;
      }
    }
    .yes-no {
      padding-top: 6px;
      span {
        border: 1px solid #e0e0e0;
        color: #d6d6d6;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 600;
        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          border-right: none;
        }
        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        &:not(:first-child):not(:last-child) {
          border-left: none;
          border-right: none;
        }
        &.checked {
          background: #e0e0e0;
          color: #313131;
        }
      }
    }
    .link {
      margin-top: 20px;
      display: inline-block;
      color: #a51010;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        color: #da5d5d;
      }
    }
    .tag {
      color: #d6d6d6;
      padding: 4px 8px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 6px;  
      margin-top: 5px;    
      margin-bottom: 5px;    
      display: inline-block;
      background: #ebebeb;
      color: #313131;  
      text-transform: capitalize;
    }
    .risk {
      display: inline-block;
      margin-top: 6px;
      span {
        text-transform: capitalize;
        border: 1px solid #ebebeb;
        color: #d6d6d6;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 6px;
        &.checked {
          background: #ebebeb;
          color: #313131;
        }
      }
      &.low span {
        color: #87e274;
        border-color: #87e274;
      }
      &.medium span {
        color: #e0c067;
        border-color: #e0c067;
      }
      &.high span {
        color: #e09367;
        border-color: #e09367;
      }      
      &.very-high span {
        color: #e06767;
        border-color: #e06767;
      }            
    }    
  }
}
