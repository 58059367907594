.SearchBar {
  position: relative;
  width: 100%;
  & > div {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  input {
    outline: none;
    box-sizing: border-box;
    padding: 10px 0 10px 35px;
    font-size: 14px;
    width: 100%;
    border: none;
    border: 1px solid #cbc5c5;
    border-radius: 8px;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #838383;
      opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #838383;
    }       
  }
  .search-icon {
    fill: #838383;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 11px;
    display: inline-block;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .results {
    box-sizing: border-box;
    position: absolute;
    background: white;
    max-height: 350px;
    overflow: auto;
    border-radius: 4px;
    width: 100%;
    left: 0;
    top: 45px;
    padding: 20px;
    box-shadow: 0px 4px 10px 0px rgba(59, 49, 49, 0.15);
    & > div {
      margin-bottom: 20px;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
    &.no-results {
      color: silver;
    }  
  }
}

@media screen and (max-width: 767px) {
  .SearchBar {
    .results {
      font-size: 12px;
    }
  }
}