.institution-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  .marker-icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #da5d5d;
    background: #a51010;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    font-weight: 600;
    flex-shrink: 0;
  }
  .marker-icon + span {
    font-weight: 500;
    margin-left: 10px;
    color: #266D8E;
  }
  &:hover, &.hovered {
    .marker-icon {
      background: #266D8E;
      border-color: #4599c0;
    }
  }
}
.institution-button + .institution-button {
  margin-top: 12px;
}
